<script setup lang="ts">
    import { useChangelogAction, useChangelogQuery } from '@/changelog/composables/changelog';
    import { useAsyncTask } from 'vue-concurrency';

    const changelogAction = useChangelogAction();
    const { logs } = useChangelogQuery();

    const task = useAsyncTask(async () => {
        await changelogAction.loadChangelog();
    }).perform();
</script>

<template>
    <div v-if="task.isRunning">loading</div>
    <div v-else-if="task.isError">Changelog cannot be loaded</div>
    <div v-else-if="task.isSuccessful">
        <table class="log-table">
            <thead>
                <tr>
                    <th class="log-header-cell">Commit Hash</th>
                    <th class="log-header-cell">Date</th>
                    <th class="log-header-cell">Author</th>
                    <th class="log-header-cell">Context</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="log in logs" :key="log.hash">
                    <td class="log-data-cell">{{ log.hash }}</td>
                    <td class="log-data-cell">{{ log.date }}</td>
                    <td class="log-data-cell">{{ log.author }}</td>
                    <td>{{ log.message }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main' as *;

    .log-table {
        border-spacing: 16px;
        border-collapse: separate;
    }

    .log-header-cell {
        text-align: start;
        white-space: nowrap;
    }

    .log-data-cell {
        white-space: nowrap;
    }
</style>
