import { defineStore } from 'pinia';
import { ChangelogResponseDto } from '@containex/portal-backend-dto';

interface ChangelogState {
    logs: ChangelogResponseDto;
}

export const useChangelogStore = defineStore('changelog', {
    state: (): ChangelogState => ({
        logs: [],
    }),
});
