import { computed, type ComputedRef } from 'vue';
import { ChangelogResponseDto } from '@containex/portal-backend-dto';
import { useChangelogStore } from '@/changelog/stores/changelog';
import { changelogApi } from '@containex/portal-backend-api-client';
import { httpClient } from '@/common/api/http-client';

export interface ChangelogQuery {
    logs: ComputedRef<ChangelogResponseDto>;
}

export interface ChangelogAction {
    loadChangelog(): Promise<void>;
}

export function useChangelogQuery(): ChangelogQuery {
    const store = useChangelogStore();

    return {
        logs: computed(() => store.logs),
    };
}

export function useChangelogAction(): ChangelogAction {
    const store = useChangelogStore();

    return {
        async loadChangelog(): Promise<void> {
            const response = await changelogApi.fetchChangelog(httpClient);

            store.logs = response.data;
        },
    };
}
